import type { KolSearchResultSortKey, FilterType, FilterEntry } from './interfaces.types';

export const SORT_KEYS: KolSearchResultSortKey[] = ['affiliation', 'email', 'location', 'name', 'firstName', 'lastName', 'phone'];
export const FILTER_TYPES: FilterType[] = ['contains', 'does-not-contain', 'equals', 'does-not-equal', 'starts-with', 'ends-with', 'is-empty', 'is-not-empty'];

export function isFilterValid<T extends string>(filter: FilterEntry<T>) {
  if (!filter.key || !filter.type) return false;

  if (!doesFilterTypeRequireValue(filter.type)) return true;

  return !!filter.value?.trim();
}

export function doesFilterTypeRequireValue(type: FilterType) {
  const types: FilterType[] = ['is-empty', 'is-not-empty'];
  return !types.includes(type);
}

export function areFiltersSimilar(mainKey: KolSearchResultSortKey, innerKey: KolSearchResultSortKey) {
  if (mainKey === innerKey) return true;

  return mainKey === 'name' &&
    (innerKey === 'firstName' || innerKey === 'lastName');
}