import { useMemo } from 'react';

export type SortFnMap<T, SortKey extends string> = Record<SortKey, (a: T, b: T) => number>;

type Props<T, SortKey extends string> = {
  items: T[];
  sortFns: SortFnMap<T, SortKey>;
  sortBy: SortKey;
  direction: 'asc' | 'desc';
};

export const useSortedItems = <T, SortKey extends string>(props: Props<T, SortKey>) => {
  const sorted = useMemo(() => {
    if (!props.sortBy || !props.items) return props.items;

    return [...props.items].sort((a, b) => props.sortFns[props.sortBy](a, b) * (props.direction === 'asc' ? 1 : -1));
  }, [props.direction, props.items, props.sortBy, props.sortFns]);

  return sorted;
};