import { z } from 'zod';

export const AppEnvironment = z.enum(['development', 'sandbox', 'production']);
export type AppEnvironment = z.infer<typeof AppEnvironment>;

export const AppPlatform = z.enum(['microsite', 'veeva']);
export type AppPlatform = z.infer<typeof AppPlatform>;

export const RuntimeEnvironmentSchema = z.enum([
  'development',
  'sandbox',
  'production',
  'production:dsi',
  'development:jnj',
  'sandbox:jnj',
]);
export type RuntimeEnvironment = z.infer<typeof RuntimeEnvironmentSchema>;