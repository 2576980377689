import { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { X } from 'react-feather';
import Select from 'react-select';
import { buildSelectStyles, buildSelectTheme } from '@/components/Chat.Filters/Presentation';
import { useDebounceCallback } from '@/hooks/useDebounce';
import { camelToSpaceCase } from '@/utils';
import { KolSearchResultsFilterContext } from './context';
import type { KolSearchTableFilters } from './interfaces.state';
import type { FilterType, KolSearchResultSortKey } from './interfaces.types';
import { SORT_KEYS, FILTER_TYPES, doesFilterTypeRequireValue } from './utils';

export const FilterPanel = () => {
  const { state, dispatch, setOpen } = useContext(KolSearchResultsFilterContext);

  useEffect(() => {
    if (!state.length) {
      setOpen(false);
    }
  }, [dispatch, setOpen, state]);

  return (
    <div>
      {state.map(f => <FilterEntry key={f.identifier} filter={f} />)}
    </div>
  );
};

type FilterEntryProps = {
  filter: KolSearchTableFilters.State[number];
};
const FilterEntry = ({ filter }: FilterEntryProps) => {
  const { dispatch } = useContext(KolSearchResultsFilterContext);
  const theme = useTheme();
  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const onKeyChange = useCallback((key: KolSearchResultSortKey) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        key,
      },
    });
  }, [dispatch, filter.identifier]);

  const onOperatorChange = useCallback((operator: FilterType) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        type: operator,
      },
    });
  }, [dispatch, filter.identifier]);

  const onValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'filter-updated',
      payload: {
        identifier: filter.identifier,
        value: e.target.value,
      },
    });
  }, [dispatch, filter.identifier]);

  const onRemoveFilter = useCallback(() => {
    dispatch({
      type: 'filter-removed',
      payload: { identifier: filter.identifier },
    });
  }, [dispatch, filter.identifier]);

  const debouncedValueChange = useDebounceCallback(onValueChange, 250);
  return (
    <FilterRow>
      <SelectDiv>
        <Select<Option<KolSearchResultSortKey>>
          options={COLUMN_OPTIONS}
          isSearchable={false}
          value={COLUMN_OPTIONS.find(x => x.value === filter.key)}
          placeholder={'Column'}
          getOptionLabel={o => camelToSpaceCase(o.label)}
          onChange={o => onKeyChange(o.value)}
          styles={selectStyles}
          theme={selectTheme} />
      </SelectDiv>
      <SelectDiv>
        <Select<Option<FilterType>>
          options={OPERATOR_OPTIONS}
          isSearchable={false}
          value={OPERATOR_OPTIONS.find(x => x.value === filter.type)}
          placeholder={'Operator'}
          getOptionLabel={o => o.label.replaceAll('-', ' ')}
          onChange={o => onOperatorChange(o.value)}
          styles={selectStyles}
          theme={selectTheme} />
      </SelectDiv>
      {doesFilterTypeRequireValue(filter.type) &&
        <Input onChange={debouncedValueChange} defaultValue={filter.value ?? ''} placeholder='Filter Value' />
      }
      <RemoveFilterIcon onClick={onRemoveFilter} />
    </FilterRow>
  );
};

const FilterRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const SelectDiv = styled.div({
  width: 175,
});

const Input = styled.input(({ theme }) => ({
  fontSize: 16,
  padding: 8,
  width: 250,
  height: 38,
  borderRadius: 5,
  border: `1px solid ${theme.palette.gray.light1} `,
  fontFamily: theme.fonts.regular,

  '&:focus': {
    borderColor: theme.isTrinityThemed ? theme.palette.blue.main : theme.palette.primary.main,
    borderWidth: 2,
  },

  '&::placeholder': {
    // color: theme.palette.gray.light1,
    fontFamily: theme.fonts.regular,
    fontSize: 14,
  },
}));

const RemoveFilterIcon = styled(X)(({ theme }) => ({
  cursor: 'pointer',
  padding: 6,
  height: 28,
  width: 28,
  borderRadius: 20,
  flexShrink: 0,
  flexGrow: 0,
  '&:hover': {
    backgroundColor: theme.palette.gray.light4,
  },
}));

function buildSelectOptions<T extends string>(options: T[]): Option<T>[] {
  return options.map(o => ({ label: o, value: o }));
}

const COLUMN_OPTIONS = buildSelectOptions(SORT_KEYS);
const OPERATOR_OPTIONS = buildSelectOptions(FILTER_TYPES);

type Option<T extends string> = {
  label: T;
  value: T;
};