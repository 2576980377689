import { z } from 'zod';
import { AppEnvironment, AppPlatform, RuntimeEnvironmentSchema } from '@/types/environment';

export const BoolLikeSchema = z.union([z.string(), z.boolean()]).transform(value => typeof value === 'boolean' ? value : /^(t(?:rue)*)|(y(?:es)*)$/i.test(value));
export const NumberLikeSchema = z.union([z.string(), z.number()]).pipe(z.coerce.number());

//
// IMPORTANT: source of truth is in build config/env.js, this is a copy of that schema until it can be shared, mirror changes there/here
//
export const BaseConfig = z.object({
  APP_ENVIRONMENT: AppEnvironment,
  APP_PLATFORM: AppPlatform,

  RUNTIME_ENVIRONMENT: RuntimeEnvironmentSchema,

  BRAND_INSIGHTS_API_URL: z.string().url(),
  BRAND_INSIGHTS_WS_PATH: z.string(),
  BRAND_INSIGHTS_WS_URL: z.string().url(),

  FRONT_BASE_URL: z.string().url(),

  SENTRY_ENABLED: BoolLikeSchema,
  SENTRY_DSN: z.string().url(),
  SENTRY_RELEASE: z.string().optional(),
}).readonly();

export type BaseConfig = z.infer<typeof BaseConfig>;