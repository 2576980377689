import { useReducer } from 'react';
import { createId } from '@paralleldrive/cuid2';
import type { KolSearchTableFilters } from '../interfaces.state';

export const useTableFilterState = () => {
  return useReducer(reducerFn, [] as KolSearchTableFilters.State);
};

function reducerFn(state: KolSearchTableFilters.State, action: KolSearchTableFilters.Actions): KolSearchTableFilters.State {

  switch (action.type) {
    case 'filters-cleared':
      return [];
    case 'filters-set':
      return action.payload;
    case 'filter-removed':
      return state.filter(f => f.identifier !== action.payload.identifier);
    case 'filter-updated': {
      return state.map(x => x.identifier === action.payload.identifier ? { ...x, ...action.payload } : x);
    }
    case 'filter-added': {
      return [...state, { identifier: createId(), type: 'contains', value: '', ...action.payload }];
    }

    default:
      return state;
  }
}