import { useState, useCallback } from 'react';

type Direction = 'asc' | 'desc';

type Props<SortKey extends string> = {
  default?: {
    sortBy: SortKey;
    direction: Direction;
  };
};

export const useTableSortState = <SortKey extends string>(props: Props<SortKey> = {}) => {
  const [sortBy, setSortBy] = useState<SortKey>(props.default?.sortBy);
  const [sortDir, setDir] = useState<Direction>(props.default?.direction);

  const setSort = useCallback((by: SortKey, direction: Direction) => {
    setSortBy(by);
    setDir(direction);
  }, []);

  const toggleSortKey = useCallback((by: SortKey) => {
    if (by !== sortBy) {
      setSortBy(by);
      setDir('asc');
    } else {
      setDir(old => !old ? 'asc' : old === 'asc' ? 'desc' : null);
    }
  }, [sortBy]);

  return {
    sortBy, sortDir, setSort, toggleSortKey,
  };
};