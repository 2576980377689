import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Frown, ChevronRight, ChevronDown } from 'react-feather';
import { Logo } from '@/components/Branding';
import { isLowerEnv } from '@/config/utils';
import { SupportEmail } from '@/consts/support';
import { usePrevious } from '@/hooks/usePrevious';
import styles from './style.module.css';

type Props = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};

export function ErrorMessage({ componentStack, error, eventId, resetError }: Props) {
  const location = useLocation();
  const previous = usePrevious(location);
  const [show, setShow] = useState<boolean>(false);
  const subject = encodeURIComponent(`I am receiving an error using the MSL Copilot application`);

  useEffect(() => {
    if (previous && previous !== location) {
      resetError();
    }
  }, [previous, location, resetError]);

  const handleReload = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.location.reload();
  }, []);

  const handleToggleError = useCallback(() => {
    setShow(prev => !prev);
  }, []);

  const errorMessage = useMemo(() => {
    if (!error) return null;
    return [`${error.name}: ${error.message}`, error.stack].filter(v => v?.length).join('\n');
  }, [error]);

  const showComponentStack = useMemo(() => isLowerEnv(), []);

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Logo.HorizontalNavLogo />
      </div>
      <div className={styles.wrong}>
        <h1>
          <div className={styles.frown}>
            <Frown />
          </div>
          {`We're sorry, something went wrong.`}
        </h1>
        {`Please try the steps below to attempt and fix your problem. If you are still experiencing issues after that, email us at `}<a className={styles.email} href={`mailto:${SupportEmail}?subject=${subject}`}>{SupportEmail}</a>
        <ol className={styles.steps}>
          <li>
            <a className={styles.link} onClick={handleReload}>Wait a moment and refresh the current page</a>
          </li>
          <li>
            <Link className={styles.link} to="/">Go to the homepage</Link>
          </li>
          <li>Try another browser or device</li>
        </ol>
        <div className={styles.block}>Please make sure adblock is disabled. These are known to block detailed error information from getting to our engineering team.</div>
        <div className={styles.id}>Event Id: {eventId}</div>
      </div>
      {errorMessage && (
        <div className={styles.error}>
          <div className={styles.errorToggle} onClick={handleToggleError}>
            <div className={styles.errorToggleIcon}>
              {show ? <ChevronDown /> : <ChevronRight />}
            </div>
            Show error message
          </div>
          {show && (
            <pre className={styles.errorCode}>
              <code>
                Event Id: {eventId}
                <br /><br />
                {errorMessage}
                {showComponentStack
                  ? (
                    <>
                      <br />
                      {componentStack}
                    </>
                  )
                  : null}
              </code>
            </pre>
          )}
        </div>
      )}
    </div>
  );
}