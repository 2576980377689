import { createSlice } from '@reduxjs/toolkit';
import type { UserState } from './interfaces';
import { sharedActions } from './shared-actions';

export const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(sharedActions.appStatedLoaded, (_, action) => {
      return action.payload.user;
    });
  },
});

export default userSlice.reducer;