import { useMemo } from 'react';
import type { FilterEntry, StringAccessorFnMap } from '../interfaces';
import { isFilterValid } from '../utils';

type Props<T, FilterKey extends string> = {
  items: T[];
  accessorFnMap: StringAccessorFnMap<T, FilterKey>;
  filters: FilterEntry<FilterKey>[];
};

export const useFilteredItems = <T, FilterKey extends string>({ items, accessorFnMap, filters }: Props<T, FilterKey>) => {
  return useMemo(() => {
    if (!filters.length) return items;

    let filtered = items;

    for (const filter of filters) {
      if (isFilterValid(filter)) {
        filtered = filtered.filter(x => testFilter(x, filter, accessorFnMap));
      }
    }

    return filtered;
  }, [accessorFnMap, filters, items]);
};

function testFilter<T, FilterKey extends string>(obj: T, filter: FilterEntry<FilterKey>, accessorFnMap: StringAccessorFnMap<T, FilterKey>) {
  const stringVal = accessorFnMap[filter.key](obj);
  switch (filter.type) {
    case 'contains':
      return sanitizeString(stringVal)?.includes(sanitizeString(filter.value));
    case 'does-not-contain':
      return !sanitizeString(stringVal)?.includes(sanitizeString(filter.value));
    case 'equals':
      return sanitizeString(stringVal) === sanitizeString(filter.value);
    case 'does-not-equal':
      return sanitizeString(stringVal) != sanitizeString(filter.value);
    case 'starts-with':
      return sanitizeString(stringVal)?.startsWith(sanitizeString(filter.value));
    case 'ends-with':
      return sanitizeString(stringVal)?.endsWith(sanitizeString(filter.value));
    case 'is-empty':
      return stringVal == null || stringVal.trim() == '';
    case 'is-not-empty':
      return !!stringVal?.trim();
    default:
      throw new UnreachableCaseError(filter.type);
  }

  function sanitizeString(value: string) {
    return value?.toLowerCase();
  }
}